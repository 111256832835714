// import { act } from 'react-dom/test-utils';
import * as ACTION_TYPES from '../actions/type';

const initalState = {
    agentSelected: [],
    isRediract: false,
    titleJob: '',
    categoryJob: 0,
    categoryJobName: '',
    categoryType: { isJobFree: false, requireLocation: false, isdeadline: true, name_app: '' },
    legalJob: 0,
    legalJobName: '',
    skillsJob: 0,
    skillsJobName: '',
    budgetJob: 0,
    budgetValJob: '',
    budgetName: '',
    deadlineJob: '',
    descriptionJob: '',
    fileArray: [],
    completedStep: 0,
    skillsArray: [],
    deleteFilesArray: [],
    isDuplicateJob: false,
    duplicateJobId: null,
    isFreelancerFromDetail: false,
    isEditJobFromList: false,
    latitude: 0,
    longitude: 0,
    jobLocation: '',
    searchTags: [],
    searchTagSuggestion: []
}

const PostJobReducers = (state = initalState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SELECTED_FREELANCER_ARRAY:
            return {
                ...state,
                agentSelected: action.payload.agent,
                isRediract: action.payload.rediract
            }
        case ACTION_TYPES.TITLE_POST_JOB:
            return {
                ...state,
                titleJob: action.payload
            }
        case ACTION_TYPES.CATEGORY_POST_JOB:
            return {
                ...state,
                categoryJob: action.payload.cateId,
                categoryJobName: action.payload.cateName,
                categoryType: (action.payload.categoryType ? action.payload.categoryType : { isJobFree: false, requireLocation: false, isdeadline: true, name_app: '' })
            }
        case ACTION_TYPES.LEGAL_POST_JOB:
            return {
                ...state,
                legalJob: action.payload.legalId,
                legalJobName: action.payload.legalName
            }
        case ACTION_TYPES.SKILLS_POST_JOB:
            return {
                ...state,
                skillsJob: action.payload.skillId,
                skillsJobName: action.payload.skillName
            }
        case ACTION_TYPES.BUDGET_POST_JOB:
            return {
                ...state,
                budgetJob: action.payload.budgetId,
                budgetName: action.payload.budgetNam,
                budgetValJob: ''
            }
        case ACTION_TYPES.BUDGET_VAL_POST_JOB:
            return {
                ...state,
                budgetJob: 0,
                budgetValJob: action.payload.budgetVal,
                budgetName: action.payload.budgetNam
            }
        case ACTION_TYPES.DEADLINE_POST_JOB:
            return {
                ...state,
                deadlineJob: action.payload
            }
        case ACTION_TYPES.DESCRIPTION_POST_JOB:
            return {
                ...state,
                descriptionJob: action.payload.discription,
                fileArray: action.payload.files,
                deleteFilesArray: action.payload.deletedFiles
            }
        case ACTION_TYPES.COMPLETED_STEP_POST_JOB:
            return {
                ...state,
                completedStep: action.payload
            }
        case ACTION_TYPES.SKILLS_ARRAY:
            return {
                ...state,
                skillsArray: action.payload
            }
        case ACTION_TYPES.JOBLOCATION:
            return {
                ...state,
                latitude: action.payload.lat,
                longitude: action.payload.long,
                jobLocation: action.payload.jobLocation,
            }
        case ACTION_TYPES.DUPLICATE_JOB:
            return {
                ...state,
                duplicateJobId: action.payload.duplicateJobId,
                isDuplicateJob: action.payload.isDuplicateJob
            }
        case ACTION_TYPES.ISFREELANCER_FROM_DETAIL:
            return {
                ...state,
                isFreelancerFromDetail: action.payload
            }
        case ACTION_TYPES.ISEDIT_FROM_LIST:
            return {
                ...state,
                isEditJobFromList: action.payload
            }
        case ACTION_TYPES.ADD_SEARCHTAG:
            return {
                ...state,
                searchTags: action.payload
            }
        default:
            return state;
    }
}

export default PostJobReducers;