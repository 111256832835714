import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { apiHalper } from './helpers/api_helper';
// import { jwtDecode } from './helpers/jwt_helper';
import * as ACTION_TYPES from './redux/actions/type';
import 'react-intl-tel-input/dist/main.css';
import Router from './routes';
import { bodyGTMHelper } from './helpers/gtm.helper';
import dotenv from 'dotenv';

dotenv.config();
// import './App.css';

class App extends Component {
  // _unmounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isSmallerScreen: false
    }
  }

  updateWindowDimensions() {
    // console.log(window.innerWidth);
    const { checkWidth } = this.props;
    if (window.innerWidth > 767) {
      this.setState({
        isSmallerScreen: false
      })
      if (this._unmounted) {
        checkWidth(false);
      }
    } else {
      this.setState({
        isSmallerScreen: true
      });
      if (this._unmounted) {
        checkWidth(true);
      }
    }

  }

  componentDidMount = async () => {
    this._unmounted = true;
    // const token = sessionStorage.getItem('token');
    // const tokenDecode = token?jwtDecode(token):null;

    bodyGTMHelper();
    
    // if(tokenDecode === null){

      const allMainSeArray = await this.getAllMainServices();
      const allSubSeArray = await this.getAllSubServices();
      const { addMainServices, addSubServies } = this.props;
      // console.log(allMainSeArray);
      if (Array.isArray(allMainSeArray) && allMainSeArray.length === 0) {
        addMainServices([]);
      }

      if (Array.isArray(allSubSeArray) && allSubSeArray.length === 0) {
        addSubServies([]);
      }

      addMainServices(allMainSeArray);
      addSubServies(allSubSeArray);
    // }
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    this._unmounted = false;
    window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  // main all Category
  getAllMainServices = async () => {
    try {
      if (this._unmounted) {
        const response = await apiHalper('getAllMainServices', 'GET', null, null);
        const res = response.data;
        // console.log(response);
        const { addMainServices } = this.props;
        if (res.status) {
          const decode = res.data;
          // const decode = jwtDecode(res.data);
          addMainServices(decode);
          return decode
          // const mainMenu = decode.filter((info) => info.isMainMenu === '1');
          // // console.log(decode);
          // this.setState({
          //   menu: mainMenu
          // });
          // addCateMenu(decode);
        } else {
          addMainServices([]);
          return [];
          // addCateMenu([]);
        }
      }

    } catch (err) {
      console.log(err);
      const { addMainServices } = this.props;
      addMainServices([]);
      return [];
    }
  }

  // all Sub Services
  getAllSubServices = async () => {
    try {
      if (this._unmounted) {
        const response = await apiHalper('getAllSubServices', 'GET', null, null);
        const res = response.data;
        // console.log(response);
        const { addSubServies } = this.props;
        if (res.status) {
          // console.log(jwtDecode(res.data));
          const decode = res.data;
          // const decode = jwtDecode(res.data);
          addSubServies(decode);
          return decode;
          // const decode = jwtDecode(res.data);
          // this.setState({
          //   subServices: decode
          // })
          // addSubServies(decode);
        } else {
          addSubServies([])
          return [];
          // this.setState({
          //   subServices: []
          // })
          // addSubServies([]);
        }
      }

    } catch (err) {
      console.log(err);
      const { addSubServies } = this.props;
      addSubServies([])
      return [];
    }
  }


  render() {

    return <Router />
  }
}

const mapDispatchToProps = dispatch => ({
  checkWidth: (data) => dispatch({ type: ACTION_TYPES.WINDOW_WIDTH_CHECK, payload: data }),
  addMainServices: (data) => dispatch({ type: ACTION_TYPES.MAIN_MENU, payload: data }),
  addSubServies: (data) => dispatch({ type: ACTION_TYPES.SUB_ALL_SERVICES, payload: data })
})

export default withRouter(connect(null, mapDispatchToProps)(App));
