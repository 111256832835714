import * as ACTION_TYPES from '../actions/type';

const initialState = {
    FreelancerList:[],
    myHiresList:[],
    savedList:[],
    totalCount:0,
    hiresCount:0,
    saveCount:0,
    imgPath:'',
    isLoader:false,
    isHLoader:false,
    isSLoader:false,
}

const FindFreelancers = (state = initialState, action)=>{
    switch(action.type){

        case ACTION_TYPES.FILTER_LOADER_TOGGLE:
            return{
                ...state,
                isLoader:action.payload
            }
        case ACTION_TYPES.FILTER_HLOADER_TOGGLE:
            return{
                ...state,
                isHLoader:action.payload
            }
        case ACTION_TYPES.FILTER_SLOADER_TOGGLE:
            return{
                ...state,
                isSLoader:action.payload
            }

        case ACTION_TYPES.FILTER_SETTINGS_CHANGE:
            return{
                ...state,
                FreelancerList:action.payload.data,
                totalCount:action.payload.totalCount !== null?action.payload.totalCount:state.totalCount,
                imgPath:action.payload.imgPath,
                isLoader:action.payload.loader
            }
        case ACTION_TYPES.GET_MYHIRES_LIST:
            return{
                ...state,
                myHiresList:action.payload.data,
                hiresCount:action.payload.totalCount !== null?action.payload.totalCount:state.hiresCount,
                imgPath:action.payload.imgPath,
                isHLoader:action.payload.loader
            }
        case ACTION_TYPES.GET_SAVED_LIST:
            return{
                ...state,
                savedList:action.payload.data,
                saveCount:action.payload.totalCount !== null?action.payload.totalCount:state.saveCount,
                imgPath:action.payload.imgPath,
                isSLoader:action.payload.loader
            }
        default:
            return state;
    }
}

export default FindFreelancers;