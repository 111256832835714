import * as ACTION_TYPES from '../actions/type';

const initialState = {
    typeWork:0
}

const HireReducers = (state= initialState, action)=>{
    switch(action.type){

        case ACTION_TYPES.TYPE_OF_WORK_VALUE:
            return{
                ...state,
                typeWork:action.payload
            }

        default:
            return state;
    }
}

export default HireReducers;