import * as ACTION_TYPES from '../actions/type';

const initialState = {
    allMainServices:[],
    allSubServices:[],
    cateId:localStorage.getItem('pageID'),
    userImg:sessionStorage.getItem('userImg')
    // userToken:localStorage.getItem('token')
}

const HeaderReducer = (state = initialState, action)=>{
    switch(action.type){
        case ACTION_TYPES.MAIN_MENU:
            return{
                ...state,
                allMainServices:action.payload
            }
        case ACTION_TYPES.SUB_ALL_SERVICES:
            return{
                ...state,
                allSubServices:action.payload
            }
        case ACTION_TYPES.CATEGORY_ID:
            return{
                ...state,
                cateId:action.payload
            }
        case ACTION_TYPES.UPDATE_USER_IMG:
            // console.log(action.payload);
            sessionStorage.setItem('userImg', action.payload);
            return{
                ...state,
                userImg:action.payload
            }
        default:
            return state;
    }
}

export default HeaderReducer;