import React, { Component, lazy, Suspense } from 'react';
// import dotenv from 'dotenv';
// import Header from '../components/Header';
// import Footer from '../components/Footer';
// import CategoryHeader from '../components/CategoryHeader';
// import Home from '../components/Home';
// import MainCategory from '../components/MainCategory';
// import AllCategory from '../components/AllCategory';
// import HowItWorksComponent from '../components/HowItWorksComponent';
// import PricingComponent from '../components/PricingComponent';
// import TermsUseComponent from '../components/TermsUseComponent';
// import AboutUsComponent from '../components/AboutUsComponent';
// import PrivacyPolicyComponent from '../components/PrivacyPolicyComponent';
// import FAQComponent from '../components/FAQComponent';
// import ContactUsComponent from '../components/ContactUsComponent';
// import Login from '../components/Login';
// import Signup from '../components/Signup';
// import RestPwd from '../components/RestPwd';
// import Page404 from '../components/Page404';
// import AllSkillsComponent from '../components/AllSkillsComponent';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import Intercom from '../components/Intercom';
import { Intercom } from '../helpers/Intercom';
// import Intercom from 'intercom-react';
// import CryptoJs from 'crypto-js';
import MainLoader from '../components/MainLoader';
import { jwtDecode } from '../helpers/jwt_helper';
import PrivateRoute from './PrivateRoute';

// import MainRoutes from './MainRoute';
// const MainLoader = lazy(() => import('../components/MainLoader'));
const Header = lazy(() => import('../components/Header'));
const Footer = lazy(() => import('../components/Footer'));
const CategoryHeader = lazy(() => import('../components/CategoryHeader'));
const Home = lazy(() => import('../components/Home'));
const MainCategory = lazy(() => import('../components/MainCategory'));
const AllCategory = lazy(() => import('../components/AllCategory'));
const HowItWorksComponent = lazy(() => import('../components/HowItWorksComponent'));
const PricingComponent = lazy(() => import('../components/PricingComponent'));
const TermsUseComponent = lazy(() => import('../components/TermsUseComponent'));
const AboutUsComponent = lazy(() => import('../components/AboutUsComponent'));
const PrivacyPolicyComponent = lazy(() => import('../components/PrivacyPolicyComponent'));
const FAQComponent = lazy(() => import('../components/FAQComponent'));
const ContactUsComponent = lazy(() => import('../components/ContactUsComponent'));
const Login = lazy(() => import('../components/Login'));
const Signup = lazy(() => import('../components/Signup'));
const RestPwd = lazy(() => import('../components/RestPwd'));
const Page404 = lazy(() => import('../components/Page404'));
const AllSkillsComponent = lazy(() => import('../components/AllSkillsComponent'));
const ClientProjectListing = lazy(() => import('../components/Client/ClientProjectListing'));
const FreelancerList = lazy(() => import('../components/Client/FreelancerList'));
const FreelancerProfile = lazy(() => import('../components/Client/FreelancerProfile'));
const ClientProfile = lazy(() => import('../components/Client/ClientProfile'));
const JobDetailsComponent = lazy(() => import('../components/Client/JobDetailsComponent'));
const PostJob = lazy(() => import('../components/Client/PostJob'));
const QRcodeComponent = lazy(() => import('../components/QRcodeComponent'));
const AfterLoginFooter = lazy(() => import('../components/Client/AfterLoginFooter'));

// const sitemaps = lazy(() => import('../components/sitemaps/sitemapIndex'));


class Router extends Component {

    componentDidMount() {
        const { location } = this.props;
        const path = location.pathname.split('/');

        if ((path[1] !== 'sitemap.xml' && path[1] !== 'sitemaps')) {
            Intercom('ck9u1r6v');
            const token = sessionStorage.getItem('token');
            const tokenDecode = token ? jwtDecode(token) : null;
            if (tokenDecode !== null) {

                var cryptoJS = require('crypto-js')
                var stringID = tokenDecode.id.toString()
                var hash = cryptoJS.HmacSHA256(stringID, 'KYAoPDpsoy2kvADIAWUVb_sky2qtRaS6y46AJ0q3')
                var hex = cryptoJS.enc.Hex.stringify(hash)

                window.Intercom('boot', {
                    app_id: 'ck9u1r6v',
                    user_id: tokenDecode.id,
                    email: tokenDecode.email,
                    name: `${tokenDecode.first_name} ${tokenDecode.last_name}`,
                    "User Type": tokenDecode.profile_type.type,
                    "Sign Up App Name": tokenDecode.profile_type.id === 2 ? "Website 24Task Client" : "Website 24Task Agent",
                    user_hash: hex
                });
            } else {
                window.Intercom('boot', {
                    app_id: 'ck9u1r6v'
                });
            }
        }

    }

    render() {

        // const token = sessionStorage.getItem('token');
        // const tokenDecode = jwtDecode(token);

        // dotenv.config();

        // let authRoutes = [];

        // if(token && tokenDecode){
        //     if(tokenDecode.profile_type.id === 2){
        //         authRoutes = [
        //             { url:'/', component:ClientProjectListing, isRedirect:false },
        //             { url:'/freelancer-list', component:FreelancerList, isRedirect:false },
        //             { url:'/freelancer-profile', component:FreelancerProfile, isRedirect:false },
        //             { url:'/client-profile', component:ClientProfile, isRedirect: false},
        //             { url:'/client-job-postlist', component:ClientPostJobList, isRedirect: false},
        //             { url:'*', component:Page404, isRedirect:false },
        //         ];
        //     }

        //     if(tokenDecode.profile_type.id === 1){
        //         authRoutes = [];
        //     }
        // }

        // const mainRoute = [
        //     { url:'/', component:Home, isPrivate:false, isRedirect:false },
        //     // redireact to home page
        //     { url:'/home', component:Home, isPrivate:false, isRedirect:true },
        //     { url:'/latest', component:Home, isPrivate:false, isRedirect:true },
        //     { url:'/how-it-works', component:HowItWorksComponent, isPrivate:false, isRedirect:false },
        //     { url:'/pricing', component:PricingComponent, isPrivate:false, isRedirect:false },
        //     { url:'/terms-of-use', component:TermsUseComponent, isPrivate:false, isRedirect:false },
        //     { url:'/about-us', component:AboutUsComponent, isPrivate:false, isRedirect:false },
        //     { url:'/privacy-policy', component:PrivacyPolicyComponent, isPrivate:false, isRedirect:false },
        //     { url:'/faqs', component:FAQComponent, isPrivate:false, isRedirect:false },
        //     { url:'/contact-us', component:ContactUsComponent, isPrivate:false, isRedirect:false },
        //     { url:'/login', component:Login, isPrivate:false, isRedirect:false },
        //     { url:'/signup', component:Signup, isPrivate:false, isRedirect:false },
        //     { url:'/reset', component:RestPwd, isPrivate:false, isRedirect:false },

        //     { url:'/project-list', component:ClientProjectListing, isPrivate:true, isRedirect:false },
        //     { url:'/freelancer-list', component:FreelancerList, isPrivate:true, isRedirect:false },
        //     { url:'/freelancer-profile', component:FreelancerProfile, isPrivate:true, isRedirect:false },
        //     { url:'/client-profile', component:ClientProfile, isPrivate:true, isRedirect: false},
        //     { url:'/client-job-postlist', component:ClientPostJobList, isPrivate:true, isRedirect: false},
        //     { url:'/pageNotFound', component:Page404, isPrivate:false, isRedirect:false },
        //     // { url:'/cat', component:Page404, isPrivate:false, isRedirect:false },


        //     // country route
        //     // { url:'/us', component:MainCategory, isPrivate:false, isRedirect:false },
        //     // { url:'/us/:subCategory', component:MainCategory, isPrivate:false, isRedirect:false },

        //     { url:'/hire', component:AllCategory, isPrivate:false, isRedirect:false },

        //     // main category route
        //     { url:'/cat/:mainCategory', component:MainCategory, isPrivate:false, isRedirect:false },

        //     // sub category route
        //     { url:'/hire/:subService', component:MainCategory, isPrivate:false, isRedirect:false },

        //     // country code / statecode / city wise route
        //     { url:'/:countryCode/allstate', component:AllSkillsComponent, isPrivate:false, isRedirect:false },
        //     { url:'/:countryCode/allskills', component:AllSkillsComponent, isPrivate:false, isRedirect:false },
        //     { url:'/:countryCode/:stateCode/allskills', component:AllSkillsComponent, isPrivate:false, isRedirect:false },
        //     { url:'/:countryCode/:stateCode/allcities', component:AllSkillsComponent, isPrivate:false, isRedirect:false },
        //     { url:'/:countryCode/:stateCode/:city/allskills', component:AllSkillsComponent, isPrivate:false, isRedirect:false },
        //     // { url:'/:countryCode/allskills', component:AllSkillsComponent, isPrivate:false, isRedirect:false },

        //     // country route
        //     { url:'/:countryCode', component:MainCategory, isPrivate:false, isRedirect:false },

        //     // country with service route
        //     { url:'/:countryCode/:subCategory', component:MainCategory, isPrivate:false, isRedirect:false },

        //     // country with state route
        //     { url:'/:countryCode/:stateCode/freelancers', component:MainCategory, isPrivate:false, isRedirect:false },

        //     // country and state with service route
        //     { url:'/:countryCode/:stateCode/:subCategory', component:MainCategory, isPrivate:false, isRedirect:false },

        //     // country, state and city freelancers route
        //     { url:'/:countryCode/:stateCode/:city/freelancers', component:MainCategory, isPrivate:false, isRedirect:false },

        //     // country, state and city with services route
        //     { url:'/:countryCode/:stateCode/:city/:subCategory', component:MainCategory, isPrivate:false, isRedirect:false },

        //     { url:'**', component:Page404, isPrivate:false, isRedirect:false },
        // ];



        const { location } = this.props;
        const path = location.pathname.split('/');

        if (!sessionStorage.getItem('token') && localStorage.getItem('userCredential') && localStorage.getItem('userCredential') !== '') {
            const item = JSON.parse(localStorage.getItem('userCredential'))
            const now = new Date()
            
            // compare the expiry time of the item with the current time
            if (now.getTime() > item.expiry) {
                localStorage.removeItem('userCredential')
            }else{
                sessionStorage.setItem('token', item.value);
            }
        }

        return (
            <Suspense fallback={(path[1] !== 'sitemap.xml' && path[1] !== 'sitemaps') ? <MainLoader /> : null}>

                <div className="fixed">
                    {(path[1] !== 'sitemap.xml' && path[1] !== 'sitemaps') ? <Header /> : null}
                    {(path[1] === '' || (path[1] !== 'login' && path[1] !== 'signup' && path[1] !== 'reset' &&
                        path[1] !== 'sitemap.xml' && path[1] !== 'sitemaps' && path[1] !== 'jobs' && path[1] !== 'find-freelancer' &&
                        path[1] !== 'freelancers' && path[1] !== 'client-profile' && path[1] !== 'post-job'))
                        ? <CategoryHeader />
                        : null}
                </div>
                <Switch>

                    {/* <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} /> */}
                    <Route exact path="/" component={Home} />
                    <Redirect to="/" from="/home" />
                    <Redirect to="/" from="/latest" />
                    <Redirect to="/" from="/index.html" />

                    {/* client page route over*/}
                    <PrivateRoute exact path="/jobs" component={ClientProjectListing} />
                    <PrivateRoute exact path="/jobs/:jobID" component={JobDetailsComponent} />
                    {/* <PrivateRoute exact path="/bidding" component={Bidding} /> */}
                    {/* <Redirect to="/reports" from="/" /> */}
                    <PrivateRoute exact path="/find-freelancer" component={FreelancerList} />
                    <Route exact path="/freelancers/:userName" component={FreelancerProfile} />
                    <PrivateRoute exact path="/client-profile" component={ClientProfile} />
                    <Route exact path="/post-job" component={PostJob} />
                    {/* <PrivateRoute exact path="/post-job" component={PostJob} /> */}

                    {/* <Route path="*"><Page404 /></Route> */}


                    {/* regular routes */}
                    <Route exact path="/how-it-works" component={HowItWorksComponent} />
                    <Route exact path="/pricing" component={PricingComponent} />
                    <Route exact path="/terms-of-use" component={TermsUseComponent} />
                    <Route exact path="/about-us" component={AboutUsComponent} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicyComponent} />
                    <Route exact path="/faqs" component={FAQComponent} />
                    <Route exact path="/contact-us" component={ContactUsComponent} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/signup" component={Signup} />
                    <Route exact path="/reset" component={RestPwd} />

                    {/* page not found */}
                    <Route path="/pageNotFound" component={Page404} />

                    <Route exact path="/hire" component={AllCategory} />

                    {/* QR code route */}
                    <Route exact path="/coupon/:code" component={QRcodeComponent} />

                    {/* ............................................................................................................................................................................ */}
                    {/* ............................................................................  Sitemap routes start  ........................................................................ */}
                    {/* ............................................................................................................................................................................ */}

                    {/* Sitemap route create by gaurav */}
                    {/* <Route exact path="/sitemap.xml" component={sitemaps} /> */}

                    {/* for 
                    1) sitemaps/main-pages-sitemap.xml
                    2) sitemaps/main-category-sitemap.xml
                    3) sitemaps/sub-category-sitemap.xml
                    4) sitemaps/country-sitemap.xml
                    */}
                    {/* <Route exact path="/sitemaps/:pagesXml" component={sitemaps} /> */}

                    {/* /sitemaps/country-states */}
                    {/* <Route exact path="/sitemaps/country/country-services-sitemap.xml" component={sitemaps} /> */}
                    {/* sub like 24task.com/sitemaps/country/country-services-sitemap-us.xml */}
                    {/* <Route exact path="/sitemaps/country/:pagesXml" component={sitemaps} /> */}

                    {/* /sitemaps/country-states */}
                    {/* <Route exact path="/sitemaps/country-states/country-states-sitemap.xml" component={sitemaps} /> */}
                    {/* sub like 24task.com/sitemaps/country-states/country-states-sitemap-*.xml. where * as countryCode
                        innerUrl : https://24task.com/us/ga/freelancers */}
                    {/* <Route exact path="/sitemaps/country-states/:pagesXml" component={sitemaps} /> */}

                    {/* /sitemaps/country-states-services */}
                    {/* <Route exact path="/sitemaps/country-states-services/country-states-sitemap.xml" component={sitemaps} /> */}
                    {/* sub like 24task.com/sitemaps/country-states-services/country-states-services-sitemap-*.xml. where * as countryCode
                        innerUrl : https://24task.com/sitemaps/country-states-services/services-in-states-country-sitemap-us-states-de.xml */}
                    {/* sub like 24task.com/sitemaps/country-states/services-in-states-country-sitemap-*-states-*.xml. where * as order in (countryCode, stateCode) 
                        innerUrl : https://24task.com/us/de/full-stack-developers */}
                    {/* <Route exact path="/sitemaps/country-states-services/:pagesXml" component={sitemaps} /> */}

                    {/* /sitemaps/country-states-city */}
                    {/* <Route exact path="/sitemaps/country-states-city/country-states-city-sitemap.xml" component={sitemaps} /> */}
                    {/* sub like 24task.com/sitemaps/country-states-city/country-states-city-freelancers-sitemap-*.xml. where * as countryCode
                        innerUrl : https://24task.com/us/de/bear/freelancers */}
                    {/* <Route exact path="/sitemaps/country-states-city/:pagesXml" component={sitemaps} /> */}

                    {/* /sitemaps/country-states-city-services */}
                    {/* <Route exact path="/sitemaps/country-states-city-services/country-states-city-services-sitemap.xml" component={sitemaps} /> */}
                    {/* sub like 24task.com/sitemaps/country-states-city-services/country-states-city-services-sitemap-*.xml. where * as countryCode
                        innerUrl : https://24task.com/sitemaps/country-states-city-services/services-in-country-ad-inState-03-inCity-encamp.xml */}
                    {/* sub like 24task.com/sitemaps/country-states-city-services/services-in-country-*-inState-*-inCity-*.xml. where * as order in (countryCode, stateCode, cityname without any spaces or gap) 
                        innerUrl : https://24task.com/ad/03/pas-de-la-casa/full-stack-developers */}
                    {/* <Route exact path="/sitemaps/country-states-city-services/:pagesXml" component={sitemaps} /> */}


                    {/* ............................................................................................................................................................................ */}
                    {/* ............................................................................  Sitemap routes ended  ........................................................................ */}
                    {/* ............................................................................................................................................................................ */}


                    {/* main services */}
                    {/* {!token && !tokenDecode ?
                            <> */}
                    {/* <Route exact path="/cat/:mainCategory" component={MainCategory} /> */}

                    {/* sub services */}
                    <Route exact path="/hire/:mainCategory/:subService" component={MainCategory} />
                    <Route exact path="/hire/:mainCategory" component={MainCategory} />

                    {/* country and state and city wise skills and state city */}
                    <Route exact path="/:countryCode/allstate" component={AllSkillsComponent} />
                    <Route exact path="/:countryCode/allskills" component={AllSkillsComponent} />
                    <Route exact path="/:countryCode/:stateCode/allskills" component={AllSkillsComponent} />
                    <Route exact path="/:countryCode/:stateCode/allcities" component={AllSkillsComponent} />
                    <Route exact path="/:countryCode/:stateCode/:city/allskills" component={AllSkillsComponent} />

                    {/* country with state route */}
                    <Route exact path="/:countryCode/:stateCode/freelancers" component={MainCategory} />

                    {/* country, state and city freelancers route */}
                    <Route exact path="/:countryCode/:stateCode/:city/freelancers" component={MainCategory} />

                    {/* country and state with service route */}
                    <Route exact path="/:countryCode/:stateCode/:subCategory" component={MainCategory} />

                    {/* country with service route */}
                    <Route exact path="/:countryCode/:subCategory" component={MainCategory} />

                    {/* country, state and city with services route */}
                    <Route exact path="/:countryCode/:stateCode/:city/:subCategory" component={MainCategory} />

                    {/* country route */}
                    <Route exact path="/:countryCode" component={MainCategory} />
                    {/* </>
                            :
                            null
                        } */}


                    <Route path="**" component={Page404} />

                </Switch>
                {/* <Intercom /> */}
                {/* <Intercom appId="ck9u1r6v" user={null} /> */}
                {(path[1] === '' || (path[1] !== 'login' && path[1] !== 'signup' && path[1] !== 'reset' &&
                    path[1] !== 'sitemap.xml' && path[1] !== 'sitemaps' && path[1] !== 'jobs' && path[1] !== 'find-freelancer' &&
                    path[1] !== 'freelancers' && path[1] !== 'client-profile' && path[1] !== 'post-job')) ?
                    <Footer /> :
                    null}

                {path[1] === 'jobs' || path[1] === 'find-freelancer' || path[1] === 'freelancers' || path[1] === 'client-profile'
                    ? <AfterLoginFooter />
                    : null}
            </Suspense>
        )
    }
}

export default withRouter(connect(null)(Router));
