import * as ACTION_TYPES from '../actions/type';

const initialState = {
    isAppModal:false,
    typeModal:localStorage.getItem('logType'),
    isSuccModal:false,
    succMessage:''
}

const AppModalReducer = ( state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.APP_MODAL_TOGGLE:
            return{
                ...state,
                isAppModal:!state.isAppModal
                // typeModal:action.payload
            }
        case ACTION_TYPES.APP_SUCCESS_MODAL_TOGGLE:
            return{
                ...state,
                isSuccModal:!state.isSuccModal,
                succMessage:action.payload
            }
        default:
            return state;
    }
}

export default AppModalReducer;