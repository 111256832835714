import React from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
// useLocation
import { jwtDecode } from '../helpers/jwt_helper';
// import Page404 from '../components/Page404';

const PrivateRoute = ({ component: Component, ...rest }) => {

    if (!sessionStorage.getItem('token') && localStorage.getItem('userCredential') && localStorage.getItem('userCredential') !== '') {
        const item = JSON.parse(localStorage.getItem('userCredential'))
        const now = new Date()

        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            localStorage.removeItem('userCredential')
        } else {
            sessionStorage.setItem('token', item.value);
        }
    }
    const token = sessionStorage.getItem('token');
    const tokenDecode = jwtDecode(token);
    // console.log(rest);
    // let ComponentToRender = component;
    // let location = useLocation();
    return (
        <Route {...rest}
            render={props =>
                !token && !tokenDecode ?
                    <Redirect to="/login" />
                    :
                    <Component {...props} />
            }
        // <Route {...rest}
        // render={(props) =>{
        //     // console.log(props);
        //     return token && tokenDecode
        //     ? <ComponentToRender {...props} />
        //     : 
        //         <Redirect
        //             to='/login'
        //         />
        //     }
        // }
        />
    );
}

// const mapStateToProps = (state) =>({
//     token:state.auth.token,
//     auth:state.auth.isAuth2
// })

export default withRouter(props => <PrivateRoute {...props} />);