import * as ACTION_TYPES from '../actions/type';

const initialState = {
    allMainService:[],
    allSubService:[]
}

const CategoryReducer = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.GET_ALL_MAIN_SERVICES:
            return{
                ...state,
                allMainService:action.payload
            }
        case ACTION_TYPES.GET_ALL_SUB_SERVICES:
            return{
                ...state,
                allSubService:action.payload
            }
        default:
            return state;
    }
}

export default CategoryReducer;