import * as ACTION_TYPES from '../actions/type';

const initialState = {
    topServcies:[],
    trendingServices:[]
}

const HomeReducer = (state = initialState, action)=>{
    switch(action.type){
        case ACTION_TYPES.GET_TOP_SERVICES:
            return{
                ...state,
                topServcies:action.payload
            }
        case ACTION_TYPES.GET_TRENDING_SERVICES:
            return{
                ...state,
                trendingServices:action.payload
            }
        default:
            return state;
    }
}

export default HomeReducer;