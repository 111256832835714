import * as ACTION_TYPES from '../actions/type';

const initialState = {
    isSmallerScreen:false
}

const WindowWidth = ( state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.WINDOW_WIDTH_CHECK:
            return{
                ...state,
                isSmallerScreen:action.payload
            }
        default:
            return state;
    }
}

export default WindowWidth;