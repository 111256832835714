import axios from 'axios';

// let url = "https://fdgz6q04s8.execute-api.us-east-2.amazonaws.com/dev/";
// let url = "https://d0ef2n19qd.execute-api.us-east-2.amazonaws.com/dev/";
let url = "https://web.24task.com/";
// api calling function
// api call without login
export const apiHalper = (api, method, data, headers) =>{

    const apiUrl = url + api;
    return new Promise((resolve, reject)=>{
        axios({
            method:method,
            url:apiUrl,
            data:data,
            headers:headers 
        })
        .then(res=>resolve(res))
        .catch(error=>reject(error));
    });
    
}

//not used for now
export const apiPHPHalper = (api, method, data, headers) =>{

    const apiUrl = `https://clientapi.24task.com/${api}`;
    return new Promise((resolve, reject)=>{
        axios({
            method:method,
            url:apiUrl,
            data:data,
            headers:headers            
        })
        .then(res=>resolve(res))
        .catch(error=>reject(error));
    });
    
}

// Client API helper - Run API
export const apiClientHelper = (fn, method, data, headers ) => {
    // const apiUrl = `http://localhost:3000/dev/${fn}`
    // const apiUrl = `https://webclient.24task.com/${fn}`
    const apiUrl = `https://bbxejv2x7k.execute-api.us-east-2.amazonaws.com/dev/${fn}`
    return new Promise((resolve, reject)=>{
        axios({
            method:method,
            url:apiUrl,
            data:data,
            headers:headers            
        })
        .then(res=>resolve(res))
        .catch(error=>reject(error));
    });
}