import * as ACTION_TYPES from '../actions/type';

const initialState = {
    bcrumbArray:[],
    pathname:'',
}

const BreadCrumbReducers = (state = initialState, action)=>{
    switch(action.type){
        case ACTION_TYPES.ADD_BREADCRUMB_ARRAY:
            return{
                ...state,
                bcrumbArray:action.payload.bread,
                pathname:action.payload.path
            }
        default:
            return state;
    }
}

export default BreadCrumbReducers;