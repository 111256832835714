export const WINDOW_WIDTH_CHECK = 'WINDOW_WIDTH_CHECK';

// Header
export const MAIN_MENU = 'MAIN_MENU';
export const CATEGORY_ID = 'CATEGORY_ID';
export const SUB_ALL_SERVICES = 'SUB_ALL_SERVICES'; 
export const UPDATE_USER_IMG = 'UPDATE_USER_IMG';

// app modal
export const APP_MODAL_TOGGLE = 'APP_MODAL_TOGGLE';
export const APP_SUCCESS_MODAL_TOGGLE = 'APP_SUCCESS_MODAL_TOGGLE';

// video modal
export const VIDEO_MODAL_TOGGLE = 'VIDEO_MODAL_TOGGLE';

// Breadcrumb
export const ADD_BREADCRUMB_ARRAY = 'ADD_BREADCRUMB_ARRAY';

// categories page
export const GET_ALL_MAIN_SERVICES = 'GET_ALL_MAIN_SERVICES';
export const GET_ALL_SUB_SERVICES = 'GET_ALL_SUB_SERVICES';

// home page
export const GET_TOP_SERVICES = 'GET_TOP_SERVICES';
export const GET_TRENDING_SERVICES = 'GET_TRENDING_SERVICES';

// See all category page
export const TYPE_OF_WORK_VALUE = 'TYPE_OF_WORK_VALUE';

// find freelancers list
export const FILTER_SETTINGS_CHANGE = 'FILTER_SETTINGS_CHANGE';
export const FILTER_LOADER_TOGGLE = 'FILTER_LOADER_TOGGLE';
export const FILTER_HLOADER_TOGGLE = 'FILTER_HLOADER_TOGGLE';
export const FILTER_SLOADER_TOGGLE = 'FILTER_SLOADER_TOGGLE';
export const GET_MYHIRES_LIST = 'GET_MYHIRES_LIST';
export const GET_SAVED_LIST = 'GET_SAVED_LIST';

// client profile
export const UPDATE_PROFILE_TOGGLE = 'UPDATE_PROFILE_TOGGLE';
export const CLIENT_CARD_MODAL_TOGGLE = 'CLIENT_CARD_MODAL_TOGGLE';
export const CLIENT_CARD_PAYPAL_ADD_UPDATE = 'CLIENT_CARD_PAYPAL_ADD_UPDATE';

// job post
export const SELECTED_FREELANCER_ARRAY = 'SELECTED_FREELANCER_ARRAY'; 
export const TITLE_POST_JOB = 'TITLE_POST_JOB';
export const CATEGORY_POST_JOB = 'CATEGORY_POST_JOB';
export const LEGAL_POST_JOB = 'LEGAL_POST_JOB';
export const SKILLS_POST_JOB = 'SKILLS_POST_JOB';
export const BUDGET_POST_JOB = 'BUDGET_POST_JOB';
export const BUDGET_VAL_POST_JOB = 'BUDGET_VAL_POST_JOB';
export const DEADLINE_POST_JOB = 'DEADLINE_POST_JOB';
export const DESCRIPTION_POST_JOB = 'DESCRIPTION_POST_JOB';
export const COMPLETED_STEP_POST_JOB = 'COMPLETED_STEP_POST_JOB'; 
export const SKILLS_ARRAY = 'SKILLS_ARRAY'; 
export const JOBLOCATION = 'JOBLOCATION'; 
export const DUPLICATE_JOB = 'DUPLICATE_JOB'; 
export const ISFREELANCER_FROM_DETAIL = 'ISFREELANCER_FROM_DETAIL'; 
export const ISEDIT_FROM_LIST = 'ISEDIT_FROM_LIST'; 
export const ADD_SEARCHTAG = 'ADD_SEARCHTAG';

// job details
export const IS_REFUND_PAY = 'IS_REFUND_PAY';
export const REFUND_MODAL_TOGGLE = 'REFUND_MODAL_TOGGLE';
export const RELEASE_MODAL_TOGGLE = 'RELEASE_MODAL_TOGGLE';
export const REVIEW_VIEW = 'REVIEW_VIEW';
export const DEPOSIT_MODAL_TOGGLE = 'DEPOSIT_MODAL_TOGGLE';
export const IS_RELEASE_DEPOSIT = 'IS_RELEASE_DEPOSIT';
export const IS_HIRE_CONFIRM = 'IS_HIRE_CONFIRM';


