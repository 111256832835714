import React from 'react';
import ReactDOM from 'react-dom';
// import ReactDOMServer from 'react-dom/server';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import store from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GTMScriptHelper } from './helpers/gtm.helper';
import './assets/css/style.css';

const browserHistory = createBrowserHistory();

// const tagManagerArgs = {
//   gtmId: 'GTM-PJTN7DT'
// }
GTMScriptHelper()

ReactDOM.hydrate(
  // <React.StrictMode>
  <Router history={browserHistory}>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// if(process.env.NODE_ENV === 'production'){
  serviceWorkerRegistration.unregister();
// }

// if(process.env.NODE_ENV === 'development'){
//   serviceWorkerRegistration.unregister();
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
