import React, { Component } from 'react';
import './index.css';

export default class MainLoader extends Component {
    render() {
        return (
            <div className="main_loader">
                <div className="loader_wrap">
                    <h2 data-text="24Task">24Task</h2>
                {/* <svg xmlns="http://www.w3.org/2000/svg" className="logo_atext" viewBox="0 0 1796.691 388.93">
                    <path id="_24Task" data-name="24Task" d="M31.494-246.826v1.221H99.854v-1.465c0-32.471,23.438-54.932,57.617-54.932,32.227,0,55.176,20.02,55.176,48.34,0,22.949-12.451,41.26-62.012,89.6L35.645-51.514V0h254.15V-61.279H135.5v-4.395l67.383-64.209c61.035-57.373,81.787-90.332,81.787-128.662,0-60.547-51.27-102.783-124.756-102.783C83.74-361.328,31.494-314.941,31.494-246.826ZM518.311,0h70.313V-61.523h46.143V-122.8H588.623V-352.295H485.352c-61.768,93.018-107.666,165.771-139.4,225.586v65.186H518.311Zm-106.2-124.512c40.283-73,71.777-122.314,104-170.41h3.906v174.316H412.109ZM860.6,0V-289.307H966.309v-62.988H681.152v62.988H786.865V0Zm214.6-49.316c-24.658,0-41.992-12.207-41.992-32.227,0-19.287,14.893-30.273,45.41-32.471l54.443-3.418v19.775C1133.057-70.312,1108.154-49.316,1075.2-49.316ZM1051.758,4.15c34.912,0,64.209-14.648,78.125-39.307h4.395V0h68.848V-183.838c0-57.617-40.283-91.309-111.572-91.309-67.627,0-113.281,31.25-117.92,81.055h65.43c5.859-16.113,22.949-24.9,48.584-24.9,29.3,0,45.41,12.7,45.41,35.156v21.973l-65.186,3.906c-66.895,3.906-104.248,32.227-104.248,81.055C963.623-27.588,1000,4.15,1051.758,4.15Zm215.088-194.336c0,41.5,25.391,66.406,77.637,77.881l48.828,10.986c23.682,5.127,33.936,13.184,33.936,26.367,0,17.334-19.043,28.809-46.631,28.809-28.32,0-45.654-10.5-51.025-28.809h-69.336c4.883,51.758,47.852,80.811,118.9,80.811,70.557,0,118.652-35.156,118.652-88.867,0-40.283-23.437-62.256-75.684-73.73l-50.537-10.986c-24.9-5.615-36.377-13.672-36.377-26.855,0-17.09,18.8-28.564,44.189-28.564,26.367,0,42.725,10.742,46.387,28.076h65.674c-3.906-51.514-44.189-80.078-112.549-80.078C1311.768-275.146,1266.846-241.211,1266.846-190.186Zm367.188,26.123h-4.395V-372.07h-71.045V0h71.045V-89.355l19.531-20.02L1727.783,0h84.229L1702.393-153.809l102.539-115.479h-80.811Z" transform="translate(-25.994 377.57)" stroke="red" stroke-width="11"/>
                </svg> */}
                </div>
            </div>
        )
    }
}
