import * as ACTION_TYPES from '../actions/type';

const initialState = {
    isUpdateProfile:false,
    isPayModal:false,
    isEditCard:false,
    isPaypal:false,
    cardEData:null,
    isCardAdd:false,
    isGPayModal:false
}

const ClientProfileReducers = (state= initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.UPDATE_PROFILE_TOGGLE:
            return{
                ...state,
                isUpdateProfile:action.payload
            }
        case ACTION_TYPES.CLIENT_CARD_MODAL_TOGGLE:
            return {
                ...state,
                isPayModal:action.payload.isOpen,
                isEditCard:action.payload.isEdit,
                isPaypal:action.payload.isPaypal,
                cardEData:action.payload.data,
                isGPayModal:action.payload.isGPayModal
            }
        case ACTION_TYPES.CLIENT_CARD_PAYPAL_ADD_UPDATE:
            return{
                ...state,
                isCardAdd:action.payload
            }
        default:
            return state;
    }
}

export default ClientProfileReducers;