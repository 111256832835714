import * as ACTION_TYPES from '../actions/type';

const initialState = {
    isVideoModal:false
}

const VideoModalReducers = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.VIDEO_MODAL_TOGGLE:
            return{
                ...state,
                isVideoModal:action.payload
            }
        default:
            return state;
    }
}

export default VideoModalReducers;