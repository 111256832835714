import { combineReducers } from 'redux';
import WindowWidth from './WidthCheck';
import AppModalReducer from './AppModalReducer';
import BreadCrumbReducers from './BreadcrumbReducer';
import HeaderReducer from './HeaderReducer';
import CategoryReducer from './CategoryReducer';
import HomeReducer from './HomeReducer';
import FindFreelancers from './FindFreelancersReducers';
import VideoModalReducers from './videoModalReducers';
import ClientProfileReducers from './ClientProfileReducers';
import HireReducers from './HireReducers';
import PostJobReducers from './PostJobReducers';
import JobDetailReducer from './JobDetailReducer';

const rootReducers = combineReducers({
    WindowWidth:WindowWidth,
    AppModal:AppModalReducer,
    breadcrumb: BreadCrumbReducers,
    headers: HeaderReducer,
    categories:CategoryReducer,
    home:HomeReducer,
    FindFreelancers,
    videoModal:VideoModalReducers,
    clientProfile:ClientProfileReducers,
    hire:HireReducers,
    postJob:PostJobReducers,
    jobDetail:JobDetailReducer
});

export default rootReducers;