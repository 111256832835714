const gtmId = "GTM-PJTN7DT";

// google tag manager script
export function GTMScriptHelper (){

        (
            function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!=='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer',gtmId
        )
}

const style={
    display:'none',
    visibility:'hidden'
}

export function bodyGTMHelper(){
    return <noscript><iframe title="gtm" src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
    height="0" width="0" style={style} aria-hidden={true}></iframe></noscript>
}


