import * as ACTION_TYPES from '../actions/type';

const initialState = {
    isRefundModal: false,
    isRefund: false,
    depositModal: false,
    isDepositPay: false,
    isConfirmHire: false,
    isReleaseOpen: false,
    isReviewOpen: false,
    fromReviewOpen: '/jobs'
}

const JobDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.IS_REFUND_PAY:
            return {
                ...state,
                isRefund: action.payload
            }
        case ACTION_TYPES.REFUND_MODAL_TOGGLE:
            return {
                ...state,
                isRefundModal: action.payload
            }
        case ACTION_TYPES.RELEASE_MODAL_TOGGLE:
            return {
                ...state,
                isReleaseOpen: action.payload
            }
        case ACTION_TYPES.REVIEW_VIEW:
            return {
                ...state,
                isReviewOpen: action.payload.isOpen,
                fromReviewOpen: action.payload.fromURL,
            }
        case ACTION_TYPES.DEPOSIT_MODAL_TOGGLE:
            return {
                ...state,
                depositModal: action.payload
            }
        case ACTION_TYPES.IS_RELEASE_DEPOSIT:
            return {
                ...state,
                isDepositPay: action.payload
            }
        case ACTION_TYPES.IS_HIRE_CONFIRM:
            return {
                ...state,
                isConfirmHire: action.payload
            }
        default:
            return state;
    }
}

export default JobDetailReducer;